import validate from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "all-flight": () => import("/builds/apzfront/forerunner-next/apps/blaze/middleware/all-flight.ts"),
  "flight-code": () => import("/builds/apzfront/forerunner-next/apps/blaze/middleware/flight-code.ts"),
  "flight-ticket": () => import("/builds/apzfront/forerunner-next/apps/blaze/middleware/flight-ticket.ts")
}