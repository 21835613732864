
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "projectName": "blaze",
  "projectVersion": "1.64.6",
  "isDev": false,
  "noScripts": false,
  "nuxt": {
    "buildId": "a8237092-2749-4371-b190-632bec491297"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
