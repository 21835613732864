export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"generator","content":"airpaz"},{"name":"format-detection","content":"telephone:no"},{"name":"msvalidate.01","content":"7541320287A5955043542DABB91B9137"},{"property":"og:site_name","content":"Airpaz"},{"property":"og:type","content":"website"},{"name":"twitter:card","content":"summary"},{"name":"twitter:url","content":"http://www.airpaz.com/"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"key":"set-vh-unit","innerHTML":" function setVhUnit() { document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px'); } setVhUnit(); document.addEventListener('resize', setVhUnit); ","type":"text/javascript"}],"noscript":[],"titleTemplate":"%s - Airpaz"}

export const appCdnURL = ""

export const appBuildAssetsDir = "/_nuxt/"

export const appBaseURL = "/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'