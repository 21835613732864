import { default as _91slug_93S8w03BRcF0Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue?macro=true";
import { default as indexnVVEiZSRAoMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue?macro=true";
import { default as _91slug_93z7qcujcSq0Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue?macro=true";
import { default as indexdAr8D3NQEmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue?macro=true";
import { default as _91slug_93Ukxj2XB8ouMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue?macro=true";
import { default as _91slug_93HgQsW7PPxEMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue?macro=true";
import { default as _91slug_93f59XN2OWKtMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue?macro=true";
import { default as indexvLIquZIOLmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue?macro=true";
import { default as _91type_93XE7y2m9KOIMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue?macro=true";
import { default as index1bZqJZeZFHMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue?macro=true";
import { default as _91slug_93pDTNvFP9uJMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue?macro=true";
import { default as _91slug_93ikH2wA01dLMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue?macro=true";
import { default as _91slug_star_93QqHlyzWq1LMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue?macro=true";
import { default as indexvYoauhrF1EMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue?macro=true";
import { default as _91slug_933chUYD59LvMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___en",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/en/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___id",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/id/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___tl",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/tl/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___zh",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/zh/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___zh-tw",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/zh-tw/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___th",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/th/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ja",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ja/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ms",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ms/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ko",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ko/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___vi",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/vi/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___it",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/it/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___de",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/de/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___es",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/es/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___hi",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/hi/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___fr",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/fr/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ru",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ru/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___bn",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/bn/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___pt",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/pt/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___km",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/km/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___nl",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/nl/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___pl",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/pl/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___tr",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/tr/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___sv",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/sv/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___cs",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/cs/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___nb",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/nb/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ro",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ro/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___el",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/el/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___da",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/da/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___uk",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/uk/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___hr",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/hr/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___ca",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/ca/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___hu",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/hu/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___sk",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/sk/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___fi",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/fi/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___sl",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/sl/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___bg",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/bg/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S8w03BRcF0Meta?.name ?? "airlines-slug___sr",
    path: _91slug_93S8w03BRcF0Meta?.path ?? "/sr/airlines/:slug()",
    meta: _91slug_93S8w03BRcF0Meta || {},
    alias: _91slug_93S8w03BRcF0Meta?.alias || [],
    redirect: _91slug_93S8w03BRcF0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___en",
    path: indexnVVEiZSRAoMeta?.path ?? "/en/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___id",
    path: indexnVVEiZSRAoMeta?.path ?? "/id/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___tl",
    path: indexnVVEiZSRAoMeta?.path ?? "/tl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___zh",
    path: indexnVVEiZSRAoMeta?.path ?? "/zh/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___zh-tw",
    path: indexnVVEiZSRAoMeta?.path ?? "/zh-tw/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___th",
    path: indexnVVEiZSRAoMeta?.path ?? "/th/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ja",
    path: indexnVVEiZSRAoMeta?.path ?? "/ja/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ms",
    path: indexnVVEiZSRAoMeta?.path ?? "/ms/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ko",
    path: indexnVVEiZSRAoMeta?.path ?? "/ko/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___vi",
    path: indexnVVEiZSRAoMeta?.path ?? "/vi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___it",
    path: indexnVVEiZSRAoMeta?.path ?? "/it/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___de",
    path: indexnVVEiZSRAoMeta?.path ?? "/de/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___es",
    path: indexnVVEiZSRAoMeta?.path ?? "/es/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hi",
    path: indexnVVEiZSRAoMeta?.path ?? "/hi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___fr",
    path: indexnVVEiZSRAoMeta?.path ?? "/fr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ru",
    path: indexnVVEiZSRAoMeta?.path ?? "/ru/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___bn",
    path: indexnVVEiZSRAoMeta?.path ?? "/bn/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___pt",
    path: indexnVVEiZSRAoMeta?.path ?? "/pt/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___km",
    path: indexnVVEiZSRAoMeta?.path ?? "/km/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___nl",
    path: indexnVVEiZSRAoMeta?.path ?? "/nl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___pl",
    path: indexnVVEiZSRAoMeta?.path ?? "/pl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___tr",
    path: indexnVVEiZSRAoMeta?.path ?? "/tr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sv",
    path: indexnVVEiZSRAoMeta?.path ?? "/sv/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___cs",
    path: indexnVVEiZSRAoMeta?.path ?? "/cs/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___nb",
    path: indexnVVEiZSRAoMeta?.path ?? "/nb/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ro",
    path: indexnVVEiZSRAoMeta?.path ?? "/ro/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___el",
    path: indexnVVEiZSRAoMeta?.path ?? "/el/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___da",
    path: indexnVVEiZSRAoMeta?.path ?? "/da/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___uk",
    path: indexnVVEiZSRAoMeta?.path ?? "/uk/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hr",
    path: indexnVVEiZSRAoMeta?.path ?? "/hr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ca",
    path: indexnVVEiZSRAoMeta?.path ?? "/ca/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hu",
    path: indexnVVEiZSRAoMeta?.path ?? "/hu/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sk",
    path: indexnVVEiZSRAoMeta?.path ?? "/sk/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___fi",
    path: indexnVVEiZSRAoMeta?.path ?? "/fi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sl",
    path: indexnVVEiZSRAoMeta?.path ?? "/sl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___bg",
    path: indexnVVEiZSRAoMeta?.path ?? "/bg/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sr",
    path: indexnVVEiZSRAoMeta?.path ?? "/sr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___en",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/en/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___id",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/id/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___tl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/tl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___zh",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/zh/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___zh-tw",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/zh-tw/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___th",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/th/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ja",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ja/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ms",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ms/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ko",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ko/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___vi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/vi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___it",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/it/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___de",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/de/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___es",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/es/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___fr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/fr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ru",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ru/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___bn",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/bn/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___pt",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/pt/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___km",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/km/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___nl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/nl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___pl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/pl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___tr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/tr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sv",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sv/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___cs",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/cs/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___nb",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/nb/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ro",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ro/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___el",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/el/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___da",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/da/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___uk",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/uk/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ca",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ca/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hu",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hu/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sk",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sk/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___fi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/fi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___bg",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/bg/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___en",
    path: indexdAr8D3NQEmMeta?.path ?? "/en/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___id",
    path: indexdAr8D3NQEmMeta?.path ?? "/id/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___tl",
    path: indexdAr8D3NQEmMeta?.path ?? "/tl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___zh",
    path: indexdAr8D3NQEmMeta?.path ?? "/zh/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___zh-tw",
    path: indexdAr8D3NQEmMeta?.path ?? "/zh-tw/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___th",
    path: indexdAr8D3NQEmMeta?.path ?? "/th/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ja",
    path: indexdAr8D3NQEmMeta?.path ?? "/ja/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ms",
    path: indexdAr8D3NQEmMeta?.path ?? "/ms/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ko",
    path: indexdAr8D3NQEmMeta?.path ?? "/ko/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___vi",
    path: indexdAr8D3NQEmMeta?.path ?? "/vi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___it",
    path: indexdAr8D3NQEmMeta?.path ?? "/it/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___de",
    path: indexdAr8D3NQEmMeta?.path ?? "/de/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___es",
    path: indexdAr8D3NQEmMeta?.path ?? "/es/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hi",
    path: indexdAr8D3NQEmMeta?.path ?? "/hi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___fr",
    path: indexdAr8D3NQEmMeta?.path ?? "/fr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ru",
    path: indexdAr8D3NQEmMeta?.path ?? "/ru/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___bn",
    path: indexdAr8D3NQEmMeta?.path ?? "/bn/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___pt",
    path: indexdAr8D3NQEmMeta?.path ?? "/pt/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___km",
    path: indexdAr8D3NQEmMeta?.path ?? "/km/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___nl",
    path: indexdAr8D3NQEmMeta?.path ?? "/nl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___pl",
    path: indexdAr8D3NQEmMeta?.path ?? "/pl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___tr",
    path: indexdAr8D3NQEmMeta?.path ?? "/tr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sv",
    path: indexdAr8D3NQEmMeta?.path ?? "/sv/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___cs",
    path: indexdAr8D3NQEmMeta?.path ?? "/cs/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___nb",
    path: indexdAr8D3NQEmMeta?.path ?? "/nb/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ro",
    path: indexdAr8D3NQEmMeta?.path ?? "/ro/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___el",
    path: indexdAr8D3NQEmMeta?.path ?? "/el/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___da",
    path: indexdAr8D3NQEmMeta?.path ?? "/da/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___uk",
    path: indexdAr8D3NQEmMeta?.path ?? "/uk/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hr",
    path: indexdAr8D3NQEmMeta?.path ?? "/hr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ca",
    path: indexdAr8D3NQEmMeta?.path ?? "/ca/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hu",
    path: indexdAr8D3NQEmMeta?.path ?? "/hu/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sk",
    path: indexdAr8D3NQEmMeta?.path ?? "/sk/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___fi",
    path: indexdAr8D3NQEmMeta?.path ?? "/fi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sl",
    path: indexdAr8D3NQEmMeta?.path ?? "/sl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___bg",
    path: indexdAr8D3NQEmMeta?.path ?? "/bg/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sr",
    path: indexdAr8D3NQEmMeta?.path ?? "/sr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___en",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/en/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___id",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/id/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___tl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/tl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___zh",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/zh/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___zh-tw",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/zh-tw/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___th",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/th/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ja",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ja/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ms",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ms/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ko",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ko/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___vi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/vi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___it",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/it/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___de",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/de/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___es",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/es/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___fr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/fr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ru",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ru/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___bn",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/bn/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___pt",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/pt/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___km",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/km/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___nl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/nl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___pl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/pl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___tr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/tr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sv",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sv/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___cs",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/cs/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___nb",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/nb/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ro",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ro/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___el",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/el/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___da",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/da/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___uk",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/uk/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ca",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ca/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hu",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hu/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sk",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sk/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___fi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/fi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___bg",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/bg/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___en",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/en/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___id",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/id/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___tl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/tl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___zh",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/zh/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___zh-tw",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/zh-tw/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___th",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/th/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ja",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ja/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ms",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ms/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ko",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ko/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___vi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/vi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___it",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/it/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___de",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/de/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___es",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/es/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___fr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/fr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ru",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ru/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___bn",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/bn/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___pt",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/pt/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___km",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/km/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___nl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/nl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___pl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/pl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___tr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/tr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sv",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sv/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___cs",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/cs/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___nb",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/nb/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ro",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ro/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___el",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/el/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___da",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/da/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___uk",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/uk/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ca",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ca/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hu",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hu/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sk",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sk/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___fi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/fi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___bg",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/bg/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___en",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/en/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___id",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/id/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___tl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/tl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___zh",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/zh/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___zh-tw",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/zh-tw/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___th",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/th/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ja",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ja/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ms",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ms/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ko",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ko/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___vi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/vi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___it",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/it/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___de",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/de/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___es",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/es/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___fr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/fr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ru",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ru/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___bn",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/bn/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___pt",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/pt/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___km",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/km/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___nl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/nl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___pl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/pl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___tr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/tr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sv",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sv/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___cs",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/cs/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___nb",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/nb/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ro",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ro/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___el",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/el/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___da",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/da/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___uk",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/uk/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ca",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ca/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hu",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hu/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sk",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sk/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___fi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/fi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___bg",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/bg/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___en",
    path: indexvLIquZIOLmMeta?.path ?? "/en/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___id",
    path: indexvLIquZIOLmMeta?.path ?? "/id/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___tl",
    path: indexvLIquZIOLmMeta?.path ?? "/tl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___zh",
    path: indexvLIquZIOLmMeta?.path ?? "/zh/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___zh-tw",
    path: indexvLIquZIOLmMeta?.path ?? "/zh-tw/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___th",
    path: indexvLIquZIOLmMeta?.path ?? "/th/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ja",
    path: indexvLIquZIOLmMeta?.path ?? "/ja/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ms",
    path: indexvLIquZIOLmMeta?.path ?? "/ms/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ko",
    path: indexvLIquZIOLmMeta?.path ?? "/ko/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___vi",
    path: indexvLIquZIOLmMeta?.path ?? "/vi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___it",
    path: indexvLIquZIOLmMeta?.path ?? "/it/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___de",
    path: indexvLIquZIOLmMeta?.path ?? "/de/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___es",
    path: indexvLIquZIOLmMeta?.path ?? "/es/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hi",
    path: indexvLIquZIOLmMeta?.path ?? "/hi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___fr",
    path: indexvLIquZIOLmMeta?.path ?? "/fr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ru",
    path: indexvLIquZIOLmMeta?.path ?? "/ru/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___bn",
    path: indexvLIquZIOLmMeta?.path ?? "/bn/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___pt",
    path: indexvLIquZIOLmMeta?.path ?? "/pt/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___km",
    path: indexvLIquZIOLmMeta?.path ?? "/km/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___nl",
    path: indexvLIquZIOLmMeta?.path ?? "/nl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___pl",
    path: indexvLIquZIOLmMeta?.path ?? "/pl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___tr",
    path: indexvLIquZIOLmMeta?.path ?? "/tr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sv",
    path: indexvLIquZIOLmMeta?.path ?? "/sv/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___cs",
    path: indexvLIquZIOLmMeta?.path ?? "/cs/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___nb",
    path: indexvLIquZIOLmMeta?.path ?? "/nb/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ro",
    path: indexvLIquZIOLmMeta?.path ?? "/ro/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___el",
    path: indexvLIquZIOLmMeta?.path ?? "/el/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___da",
    path: indexvLIquZIOLmMeta?.path ?? "/da/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___uk",
    path: indexvLIquZIOLmMeta?.path ?? "/uk/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hr",
    path: indexvLIquZIOLmMeta?.path ?? "/hr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ca",
    path: indexvLIquZIOLmMeta?.path ?? "/ca/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hu",
    path: indexvLIquZIOLmMeta?.path ?? "/hu/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sk",
    path: indexvLIquZIOLmMeta?.path ?? "/sk/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___fi",
    path: indexvLIquZIOLmMeta?.path ?? "/fi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sl",
    path: indexvLIquZIOLmMeta?.path ?? "/sl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___bg",
    path: indexvLIquZIOLmMeta?.path ?? "/bg/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sr",
    path: indexvLIquZIOLmMeta?.path ?? "/sr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___en",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/en/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___id",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/id/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___tl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/tl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___zh",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/zh/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___zh-tw",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/zh-tw/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___th",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/th/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ja",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ja/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ms",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ms/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ko",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ko/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___vi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/vi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___it",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/it/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___de",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/de/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___es",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/es/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___fr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/fr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ru",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ru/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___bn",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/bn/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___pt",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/pt/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___km",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/km/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___nl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/nl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___pl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/pl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___tr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/tr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sv",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sv/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___cs",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/cs/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___nb",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/nb/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ro",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ro/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___el",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/el/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___da",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/da/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___uk",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/uk/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ca",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ca/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hu",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hu/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sk",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sk/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___fi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/fi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___bg",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/bg/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___en",
    path: index1bZqJZeZFHMeta?.path ?? "/en/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___id",
    path: index1bZqJZeZFHMeta?.path ?? "/id/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___tl",
    path: index1bZqJZeZFHMeta?.path ?? "/tl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___zh",
    path: index1bZqJZeZFHMeta?.path ?? "/zh/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___zh-tw",
    path: index1bZqJZeZFHMeta?.path ?? "/zh-tw/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___th",
    path: index1bZqJZeZFHMeta?.path ?? "/th/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ja",
    path: index1bZqJZeZFHMeta?.path ?? "/ja/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ms",
    path: index1bZqJZeZFHMeta?.path ?? "/ms/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ko",
    path: index1bZqJZeZFHMeta?.path ?? "/ko/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___vi",
    path: index1bZqJZeZFHMeta?.path ?? "/vi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___it",
    path: index1bZqJZeZFHMeta?.path ?? "/it/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___de",
    path: index1bZqJZeZFHMeta?.path ?? "/de/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___es",
    path: index1bZqJZeZFHMeta?.path ?? "/es/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hi",
    path: index1bZqJZeZFHMeta?.path ?? "/hi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___fr",
    path: index1bZqJZeZFHMeta?.path ?? "/fr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ru",
    path: index1bZqJZeZFHMeta?.path ?? "/ru/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___bn",
    path: index1bZqJZeZFHMeta?.path ?? "/bn/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___pt",
    path: index1bZqJZeZFHMeta?.path ?? "/pt/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___km",
    path: index1bZqJZeZFHMeta?.path ?? "/km/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___nl",
    path: index1bZqJZeZFHMeta?.path ?? "/nl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___pl",
    path: index1bZqJZeZFHMeta?.path ?? "/pl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___tr",
    path: index1bZqJZeZFHMeta?.path ?? "/tr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sv",
    path: index1bZqJZeZFHMeta?.path ?? "/sv/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___cs",
    path: index1bZqJZeZFHMeta?.path ?? "/cs/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___nb",
    path: index1bZqJZeZFHMeta?.path ?? "/nb/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ro",
    path: index1bZqJZeZFHMeta?.path ?? "/ro/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___el",
    path: index1bZqJZeZFHMeta?.path ?? "/el/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___da",
    path: index1bZqJZeZFHMeta?.path ?? "/da/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___uk",
    path: index1bZqJZeZFHMeta?.path ?? "/uk/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hr",
    path: index1bZqJZeZFHMeta?.path ?? "/hr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ca",
    path: index1bZqJZeZFHMeta?.path ?? "/ca/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hu",
    path: index1bZqJZeZFHMeta?.path ?? "/hu/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sk",
    path: index1bZqJZeZFHMeta?.path ?? "/sk/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___fi",
    path: index1bZqJZeZFHMeta?.path ?? "/fi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sl",
    path: index1bZqJZeZFHMeta?.path ?? "/sl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___bg",
    path: index1bZqJZeZFHMeta?.path ?? "/bg/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sr",
    path: index1bZqJZeZFHMeta?.path ?? "/sr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___en",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/en/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___id",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/id/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___tl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/tl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___zh",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/zh/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___zh-tw",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/zh-tw/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___th",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/th/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ja",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ja/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ms",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ms/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ko",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ko/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___vi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/vi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___it",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/it/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___de",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/de/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___es",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/es/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___fr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/fr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ru",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ru/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___bn",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/bn/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___pt",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/pt/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___km",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/km/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___nl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/nl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___pl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/pl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___tr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/tr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sv",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sv/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___cs",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/cs/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___nb",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/nb/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ro",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ro/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___el",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/el/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___da",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/da/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___uk",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/uk/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ca",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ca/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hu",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hu/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sk",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sk/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___fi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/fi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___bg",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/bg/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___en",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/en/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___id",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/id/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___tl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/tl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___zh",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/zh/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___zh-tw",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/zh-tw/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___th",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/th/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ja",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ja/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ms",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ms/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ko",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ko/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___vi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/vi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___it",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/it/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___de",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/de/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___es",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/es/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___fr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/fr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ru",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ru/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___bn",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/bn/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___pt",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/pt/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___km",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/km/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___nl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/nl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___pl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/pl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___tr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/tr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sv",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sv/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___cs",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/cs/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___nb",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/nb/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ro",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ro/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___el",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/el/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___da",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/da/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___uk",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/uk/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ca",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ca/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hu",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hu/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sk",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sk/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___fi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/fi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___bg",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/bg/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___en",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/en/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___id",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/id/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___tl",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/tl/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___zh",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/zh/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___zh-tw",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/zh-tw/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___th",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/th/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ja",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ja/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ms",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ms/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ko",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ko/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___vi",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/vi/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___it",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/it/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___de",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/de/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___es",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/es/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___hi",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/hi/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___fr",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/fr/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ru",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ru/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___bn",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/bn/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___pt",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/pt/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___km",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/km/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___nl",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/nl/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___pl",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/pl/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___tr",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/tr/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___sv",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/sv/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___cs",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/cs/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___nb",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/nb/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ro",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ro/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___el",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/el/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___da",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/da/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___uk",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/uk/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___hr",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/hr/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___ca",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/ca/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___hu",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/hu/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___sk",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/sk/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___fi",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/fi/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___sl",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/sl/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___bg",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/bg/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93QqHlyzWq1LMeta?.name ?? "hotel-city-slug_city-slug_star___sr",
    path: _91slug_star_93QqHlyzWq1LMeta?.path ?? "/sr/hotel/city/:slug_city()/:slug_star()",
    meta: _91slug_star_93QqHlyzWq1LMeta || {},
    alias: _91slug_star_93QqHlyzWq1LMeta?.alias || [],
    redirect: _91slug_star_93QqHlyzWq1LMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star].vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___en",
    path: indexvYoauhrF1EMeta?.path ?? "/en/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___id",
    path: indexvYoauhrF1EMeta?.path ?? "/id/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___tl",
    path: indexvYoauhrF1EMeta?.path ?? "/tl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___zh",
    path: indexvYoauhrF1EMeta?.path ?? "/zh/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___zh-tw",
    path: indexvYoauhrF1EMeta?.path ?? "/zh-tw/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___th",
    path: indexvYoauhrF1EMeta?.path ?? "/th/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ja",
    path: indexvYoauhrF1EMeta?.path ?? "/ja/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ms",
    path: indexvYoauhrF1EMeta?.path ?? "/ms/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ko",
    path: indexvYoauhrF1EMeta?.path ?? "/ko/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___vi",
    path: indexvYoauhrF1EMeta?.path ?? "/vi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___it",
    path: indexvYoauhrF1EMeta?.path ?? "/it/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___de",
    path: indexvYoauhrF1EMeta?.path ?? "/de/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___es",
    path: indexvYoauhrF1EMeta?.path ?? "/es/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hi",
    path: indexvYoauhrF1EMeta?.path ?? "/hi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___fr",
    path: indexvYoauhrF1EMeta?.path ?? "/fr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ru",
    path: indexvYoauhrF1EMeta?.path ?? "/ru/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___bn",
    path: indexvYoauhrF1EMeta?.path ?? "/bn/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___pt",
    path: indexvYoauhrF1EMeta?.path ?? "/pt/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___km",
    path: indexvYoauhrF1EMeta?.path ?? "/km/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___nl",
    path: indexvYoauhrF1EMeta?.path ?? "/nl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___pl",
    path: indexvYoauhrF1EMeta?.path ?? "/pl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___tr",
    path: indexvYoauhrF1EMeta?.path ?? "/tr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sv",
    path: indexvYoauhrF1EMeta?.path ?? "/sv/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___cs",
    path: indexvYoauhrF1EMeta?.path ?? "/cs/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___nb",
    path: indexvYoauhrF1EMeta?.path ?? "/nb/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ro",
    path: indexvYoauhrF1EMeta?.path ?? "/ro/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___el",
    path: indexvYoauhrF1EMeta?.path ?? "/el/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___da",
    path: indexvYoauhrF1EMeta?.path ?? "/da/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___uk",
    path: indexvYoauhrF1EMeta?.path ?? "/uk/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hr",
    path: indexvYoauhrF1EMeta?.path ?? "/hr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ca",
    path: indexvYoauhrF1EMeta?.path ?? "/ca/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hu",
    path: indexvYoauhrF1EMeta?.path ?? "/hu/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sk",
    path: indexvYoauhrF1EMeta?.path ?? "/sk/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___fi",
    path: indexvYoauhrF1EMeta?.path ?? "/fi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sl",
    path: indexvYoauhrF1EMeta?.path ?? "/sl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___bg",
    path: indexvYoauhrF1EMeta?.path ?? "/bg/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sr",
    path: indexvYoauhrF1EMeta?.path ?? "/sr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___en",
    path: _91slug_933chUYD59LvMeta?.path ?? "/en/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___id",
    path: _91slug_933chUYD59LvMeta?.path ?? "/id/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___tl",
    path: _91slug_933chUYD59LvMeta?.path ?? "/tl/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___zh",
    path: _91slug_933chUYD59LvMeta?.path ?? "/zh/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___zh-tw",
    path: _91slug_933chUYD59LvMeta?.path ?? "/zh-tw/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___th",
    path: _91slug_933chUYD59LvMeta?.path ?? "/th/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ja",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ja/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ms",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ms/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ko",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ko/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___vi",
    path: _91slug_933chUYD59LvMeta?.path ?? "/vi/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___it",
    path: _91slug_933chUYD59LvMeta?.path ?? "/it/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___de",
    path: _91slug_933chUYD59LvMeta?.path ?? "/de/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___es",
    path: _91slug_933chUYD59LvMeta?.path ?? "/es/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___hi",
    path: _91slug_933chUYD59LvMeta?.path ?? "/hi/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___fr",
    path: _91slug_933chUYD59LvMeta?.path ?? "/fr/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ru",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ru/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___bn",
    path: _91slug_933chUYD59LvMeta?.path ?? "/bn/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___pt",
    path: _91slug_933chUYD59LvMeta?.path ?? "/pt/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___km",
    path: _91slug_933chUYD59LvMeta?.path ?? "/km/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___nl",
    path: _91slug_933chUYD59LvMeta?.path ?? "/nl/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___pl",
    path: _91slug_933chUYD59LvMeta?.path ?? "/pl/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___tr",
    path: _91slug_933chUYD59LvMeta?.path ?? "/tr/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___sv",
    path: _91slug_933chUYD59LvMeta?.path ?? "/sv/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___cs",
    path: _91slug_933chUYD59LvMeta?.path ?? "/cs/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___nb",
    path: _91slug_933chUYD59LvMeta?.path ?? "/nb/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ro",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ro/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___el",
    path: _91slug_933chUYD59LvMeta?.path ?? "/el/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___da",
    path: _91slug_933chUYD59LvMeta?.path ?? "/da/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___uk",
    path: _91slug_933chUYD59LvMeta?.path ?? "/uk/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___hr",
    path: _91slug_933chUYD59LvMeta?.path ?? "/hr/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___ca",
    path: _91slug_933chUYD59LvMeta?.path ?? "/ca/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___hu",
    path: _91slug_933chUYD59LvMeta?.path ?? "/hu/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___sk",
    path: _91slug_933chUYD59LvMeta?.path ?? "/sk/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___fi",
    path: _91slug_933chUYD59LvMeta?.path ?? "/fi/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___sl",
    path: _91slug_933chUYD59LvMeta?.path ?? "/sl/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___bg",
    path: _91slug_933chUYD59LvMeta?.path ?? "/bg/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933chUYD59LvMeta?.name ?? "hotel-country-slug___sr",
    path: _91slug_933chUYD59LvMeta?.path ?? "/sr/hotel/country/:slug()",
    meta: _91slug_933chUYD59LvMeta || {},
    alias: _91slug_933chUYD59LvMeta?.alias || [],
    redirect: _91slug_933chUYD59LvMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug].vue").then(m => m.default || m)
  }
]